

const PreFooter = () => {
    return ( 
        <>  
            <div className="prefooter-conteiner">
                <div className="wrapper">
                    <div className="prefooter-content-container">
                        <div className="prefooter">
                            <p className="prefooter">                             
                                $MEMOS is a meme coin with no intrinsic value <br /> or expectation of financial return. <br />
                                The coin is completely useless and for <br /> entertainment purposes only.
                            </p> 
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    );
}
 
export default PreFooter;