import noise from "../assets/images/noise.png";

const Footer = () => {
    
    
      
    return ( 
        <>
            <div className="footer">
               <p className="footer"> &copy; 2024 by $MEMOS. All rights reserved!</p> 

            </div>
        </>
    );
}
 
export default Footer;