import airdrop from "../assets/images/airdrop.svg";

const Content = () => {
    return ( 
        <> 
        <div className="airdrop">
            <div className="wrapper_">
                    <div className="airdrop-container">
                        <div className="airdrop-container-content">
                            <div className="airdrop-content">
                                <img src={airdrop} alt="" />
                                <div className="airdrop-content-item">
                                    <span className="content-color">Wen airdrop and <br /> token launch?</span>
                                    <p className="content-text">
                                        PINKSALE FAIRLAUNCH WILL OCCUR <br />
                                        ON TUESDAY, MAY 28, at 16.00 UTC 
                                        <br />
                                        <br />
                                        $MEMOS tokens will be airdropped <br /> to
                                        your wallet upon trading <br /> launch
                                    </p>  
                                </div>
                            </div>
                        </div>
                 </div>
            </div>
        </div>
        </>
    );
}
 
export default Content;