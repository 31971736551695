import mem_two from "../assets/images/mem_two.png";
import { useClipboard } from 'use-clipboard-copy';
import copy from "../assets/images/copy.svg";

const Preheader = () => {

    const clipboard = useClipboard({
        onSuccess() {
          console.log('Text was copied successfully!')
        },
        onError() {
          console.log('Failed to copy text!')
        }
      });

    return ( 
        <>
            <div className="tokenomics">
                <div className="wrapper">
                <div className="copy">
                    <h2>CA:</h2>
                    <div className="copylabel"><input type="text" ref={clipboard.target} value="0x485c09B9d427Fe1dcD2B30411DC62b9c80290E1b"></input><button className="copybutton" onClick={clipboard.copy}><img className="copyicon" src={copy} alt="" /></button></div>
                </div>
                <div className="content-cont-dog">
                            <div className="content-dog">
                                <p className="content"> 
                                    <span>Memos</span> was inspired by Dutch artist <br /> Margriet van Breevoort,
                                    known for <br /> creating the Homunculus loxodontus <br /> statue <br /> 
                                    This famous and whimsical <br /> figure has captured the playful
                                    spirit <br /> of the internet <br />
                                    <br />
                                    <span>The ticker is $MEMOS.</span> <br />Located on Base Blockchain <br />
                                    Based and experienced <br /> team behind the project 
                                </p>  
                            </div>
                            <div className="content-img-dog">
                                <img src={mem_two} alt="" />
                            </div>
                        </div>
                </div>
            </div>
        </>
    );
}
 
export default Preheader;