import memonomics from "../assets/images/memonomics.png";


const Tokenomics = () => {

    return ( 
        <>
            <div className="tokenomics">
                <div className="wrapper_">
                    <div className="tokenomics-container">
                        <img src={memonomics} alt="" />
                        <div className="tokenomics-container-content">
                            <h2>MEMONOMICS</h2>
                            <ul className="tokenomics-content">
                                <li><p>Total supply: 1,000,000,000 $Memos</p></li>
                                <li><p>Fairlaunch through Pinksale</p></li>
                                <li><p>60% $MEMOS goes to presale participants</p></li>
                                <li><p>30% $MEMOS and raised $ETH goes to the LP</p></li>
                                <li><p>10% $MEMOS AIRDROPS/GIVEAWAYS/TEAM (LOCKED)</p></li>
                                <li><p>LP LOCKED</p></li>
                                <li><p>No Taxes</p></li>
                                <li><p>CONTRACT REVOKED/UNMINTABLE/IMMUTABLE</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    );
}
 
export default Tokenomics;